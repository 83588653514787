.login-container {
  height: 100vh;
  display: flex;
  background: #ffffff;
  position: relative;
  overflow: hidden;
}

.login-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(59, 130, 246, 0.05) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(99, 102, 241, 0.05) 0%, transparent 50%);
  z-index: 0;
}

.login-left {
  flex: 1;
  background: #f8fafc;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.login-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80');
  background-size: cover;
  background-position: center;
  opacity: 0.03;
  transform: scale(1.1);
  transition: transform 20s ease-in-out;
}

.login-left:hover::before {
  transform: scale(1.2);
}

.login-left-content {
  position: relative;
  z-index: 1;
  color: #1e293b;
  max-width: 450px;
  margin: 0 auto;
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.login-left:hover .login-left-content {
  transform: translateY(-10px);
}

.login-left-title {
  font-size: 2.2rem;
  font-weight: 800;
  margin-bottom: 16px;
  line-height: 1.2;
  background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
}

.login-left-title::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #3b82f6, #60a5fa);
  border-radius: 2px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.login-left:hover .login-left-title::after {
  transform: scaleX(1);
}

.login-left-subtitle {
  font-size: 1rem;
  color: #64748b;
  margin-bottom: 32px;
  line-height: 1.6;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
  animation-delay: 0.2s;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
  animation-delay: 0.4s;
}

.feature-item {
  background: white;
  padding: 16px;
  border-radius: 16px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid #e2e8f0;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.feature-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: linear-gradient(to bottom, #3b82f6, #60a5fa);
  opacity: 0;
  transition: opacity 0.4s ease;
}

.feature-item::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(59, 130, 246, 0.1), rgba(99, 102, 241, 0.1));
  opacity: 0;
  transition: opacity 0.4s ease;
}

.feature-item:hover {
  transform: translateY(-4px) scale(1.01);
  box-shadow: 
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-color: rgba(59, 130, 246, 0.2);
}

.feature-item:hover::before {
  opacity: 1;
}

.feature-item:hover::after {
  opacity: 1;
}

.feature-icon {
  font-size: 20px;
  margin-bottom: 12px;
  color: #3b82f6;
  transition: transform 0.4s ease;
}

.feature-item:hover .feature-icon {
  transform: scale(1.2) rotate(5deg);
}

.feature-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 6px;
  color: #1e293b;
  transition: color 0.3s ease;
}

.feature-item:hover .feature-title {
  color: #3b82f6;
}

.feature-description {
  font-size: 0.85rem;
  color: #64748b;
  line-height: 1.4;
  transition: color 0.3s ease;
}

.feature-item:hover .feature-description {
  color: #475569;
}

.login-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  position: relative;
  background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.login-right::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(255, 255, 255, 0.1) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, rgba(255, 255, 255, 0.1) 0%, transparent 50%);
  animation: pulse 8s ease-in-out infinite;
}

/* Stylish irregular separation */
.login-right::after {
  content: '';
  position: absolute;
  left: -100px;
  top: 0;
  bottom: 0;
  width: 200px;
  background: #f8fafc;
  transform: skewX(-15deg);
  box-shadow: -10px 0 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.login-content {
  width: 100%;
  max-width: 380px;
  position: relative;
  z-index: 2;
  animation: slideInRight 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.login-card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 24px;
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  backdrop-filter: blur(10px);
}

.login-card:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 30px 60px rgba(0, 0, 0, 0.15),
    0 0 0 1px rgba(255, 255, 255, 0.2);
}

.login-header {
  text-align: center;
  margin-bottom: 24px;
  position: relative;
  padding: 24px 0;
}

.login-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, #3b82f6, #60a5fa);
  border-radius: 2px;
  transition: width 0.3s ease;
}

.login-card:hover .login-header::after {
  width: 100px;
}

/* Ant Design overrides with enhanced styling */
.login-card .ant-card-head {
  border-bottom: none;
  padding-bottom: 0;
}

.login-card .ant-card-body {
  padding: 20px 24px;
}

.login-card .ant-form-item {
  margin-bottom: 16px;
}

.login-card .ant-input-affix-wrapper {
  border-radius: 12px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid #e2e8f0;
  height: 48px;
  background: rgba(255, 255, 255, 0.8);
}

.login-card .ant-input-affix-wrapper:hover,
.login-card .ant-input-affix-wrapper-focused {
  border-color: #3b82f6;
  box-shadow: 
    0 0 0 3px rgba(59, 130, 246, 0.1),
    0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.login-card .ant-btn {
  height: 48px;
  border-radius: 12px;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}

.login-card .ant-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.login-card .ant-btn:hover::before {
  left: 100%;
}

.login-card .ant-btn-primary {
  background: linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%);
  border: none;
  position: relative;
  overflow: hidden;
}

.login-card .ant-btn-primary:hover {
  background: linear-gradient(135deg, #2563eb 0%, #3b82f6 100%);
  transform: translateY(-2px);
  box-shadow: 
    0 8px 20px rgba(59, 130, 246, 0.2),
    0 0 0 1px rgba(59, 130, 246, 0.1);
}

.login-card .ant-select {
  width: 100%;
}

.login-card .ant-select-selector {
  border-radius: 12px !important;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
  border: 2px solid #e2e8f0 !important;
  height: 48px !important;
  background: rgba(255, 255, 255, 0.8) !important;
}

.login-card .ant-select-focused .ant-select-selector {
  border-color: #3b82f6 !important;
  box-shadow: 
    0 0 0 3px rgba(59, 130, 246, 0.1),
    0 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
  transform: translateY(-2px);
}

/* Enhanced Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

/* Responsive adjustments with enhanced animations */
@media (max-width: 992px) {
  .login-container {
    height: auto;
    min-height: 100vh;
    flex-direction: column;
  }

  .login-left {
    padding: 32px 20px;
    clip-path: none;
    order: 2;
  }

  .login-left-content {
    text-align: center;
    max-width: 100%;
  }

  .feature-grid {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }

  .login-right {
    padding: 32px 20px;
    clip-path: none;
    order: 1;
    min-height: 60vh;
    background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
  }

  .login-right::after {
    display: none;
  }

  .login-card {
    border-radius: 20px;
    margin: 0 auto;
  }

  .login-content {
    max-width: 400px;
    margin: 0 auto;
  }

  .login-header {
    margin-bottom: 24px;
    padding: 20px 0;
  }

  .login-left-title {
    font-size: 2rem;
    margin-bottom: 12px;
  }

  .login-left-subtitle {
    font-size: 1rem;
    margin-bottom: 24px;
  }
}

@media (max-width: 480px) {
  .login-content {
    max-width: 100%;
  }

  .login-card .ant-card-body {
    padding: 16px;
  }

  .login-header {
    margin-bottom: 20px;
    padding: 16px 0;
  }

  .login-left-title {
    font-size: 1.8rem;
  }

  .feature-item {
    border-radius: 12px;
    padding: 12px;
  }

  .login-right {
    min-height: 50vh;
    padding: 24px 16px;
  }

  .login-content {
    max-width: 100%;
  }

  .login-card {
    border-radius: 16px;
  }
}

/* Add smooth transition for order change */
.login-left,
.login-right {
  transition: order 0.3s ease;
} 