.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes highlightRow {
  0% {
    background-color: #e6f7ff;
  }
  50% {
    background-color: #bae7ff;
  }
  100% {
    background-color: #e6f7ff;
  }
}

.highlighted-row {
  animation: highlightRow 3s ease-in-out;
}

.highlighted-row td {
  background-color: transparent !important;
}

/* Add a subtle border to make the highlight more visible */
.highlighted-row td:first-child {
  border-left: 2px solid #1890ff;
}

.highlighted-row td:last-child {
  border-right: 2px solid #1890ff;
}

/* Ensure the highlight is visible on hover */
.highlighted-row:hover td {
  background-color: #e6f7ff !important;
}
