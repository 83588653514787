:root {
  /* Login Page Colors */
  --login-primary-blue: #3b82f6;
  --login-secondary-blue: #60a5fa;
  --login-background: #f8fafc;
  --login-card-bg: #ffffff;
  --login-text-dark: #1e293b;
  --login-text-medium: #64748b;
  --login-text-light: #475569;
  --login-border: #e2e8f0;
  --login-gradient-start: #1e293b;
  --login-gradient-end: #334155;
  --login-shadow: rgba(0, 0, 0, 0.1);
  --login-shadow-light: rgba(0, 0, 0, 0.06);

  /* Global Theme Colors */
  --primary-color: #3b82f6;
  --secondary-color: #60a5fa;
  --accent-color: #FFE66D;
  --background-color: #f8fafc;
  --surface-color: #ffffff;
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --text-tertiary: #475569;
  --border-color: #e2e8f0;
  --hover-color: #f1f2f6;
  --success-color: #00B894;
  --warning-color: #FDCB6E;
  --error-color: #FF7675;
  --side-menu-bg: #001529;
  --shadow-color: rgba(0, 0, 0, 0.05);
}

body {
  margin: 0;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-primary);
  background-image: 
    url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23FFE66D' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4l-2-2V20l-2-2V0h-2v18l-2 2v8l-2 2v4H0v2h36v-2zm0-30V0h-2v4h2zM6 34v-4l-2-2V20l-2-2V0H0v18l-2 2v8l-2 2v4h6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

code {
  font-family: 'Fira Code', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 12px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(5px);
}

.logo:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.ant-layout-sider {
  background: var(--primary-color);
  box-shadow: 2px 0 8px var(--shadow-color);
  position: relative;
  overflow: hidden;
}

.ant-layout-sider::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.1;
  pointer-events: none;
}

.ant-layout-header {
  background: var(--surface-color);
  padding: 0 24px;
  box-shadow: 0 2px 8px var(--shadow-color);
  border-bottom: 1px solid var(--border-color);
  height: 64px;
  position: relative;
  overflow: hidden;
}

.ant-layout-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
  opacity: 0.5;
}

.ant-layout-content {
  margin: 24px 16px;
  padding: 24px;
  background: var(--surface-color);
  min-height: 280px;
  border-radius: 16px;
  box-shadow: 0 2px 8px var(--shadow-color);
  position: relative;
  overflow: hidden;
}

.ant-layout-content::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23FFE66D' fill-opacity='0.1'%3E%3Cpath d='M12 2L1 12h3v9h7v-6h2v6h7v-9h3L12 2zm0 2.84L19.93 12H18v7h-4v-6H10v6H6v-7H4.07L12 4.84z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.1;
  pointer-events: none;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 3px;
  transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--text-secondary);
}

/* Ant Design overrides */
.ant-btn {
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.ant-btn-primary {
  background: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: 0 2px 4px rgba(255, 107, 107, 0.2);
}

.ant-btn-primary:hover {
  background: var(--primary-color);
  border-color: var(--primary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(255, 107, 107, 0.3);
}

.ant-menu-item {
  border-radius: 8px;
  margin: 4px 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.ant-menu-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: var(--accent-color);
  transform: scaleY(0);
  transition: transform 0.3s ease;
}

.ant-menu-item-selected::before {
  transform: scaleY(1);
}

.ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.1) !important;
  transform: translateX(4px);
}

.ant-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
  transform: translateX(4px);
}

.ant-card {
  border-radius: 16px;
  box-shadow: 0 2px 8px var(--shadow-color);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--border-color);
  overflow: hidden;
}

.ant-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px var(--shadow-color);
}

.ant-table {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 2px 8px var(--shadow-color);
}

.ant-table-thead > tr > th {
  background: var(--hover-color);
  font-weight: 500;
}

.ant-table-tbody > tr:hover > td {
  background: var(--hover-color);
}

.ant-input, .ant-select-selector {
  border-radius: 8px;
  border-color: var(--border-color);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.ant-input:hover, .ant-select-selector:hover {
  border-color: var(--primary-color);
}

.ant-input:focus, .ant-select-selector:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(255, 107, 107, 0.1);
}

/* Custom animations */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.float {
  animation: float 3s ease-in-out infinite;
}

.bounce {
  animation: bounce 2s ease-in-out infinite;
}

/* Status colors */
.status-success {
  color: var(--success-color);
}

.status-warning {
  color: var(--warning-color);
}

.status-error {
  color: var(--error-color);
}

/* Custom transitions */
.hover-lift {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hover-lift:hover {
  transform: translateY(-4px);
}

.hover-scale {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hover-scale:hover {
  transform: scale(1.02);
}

/* School-themed decorative elements */
.school-icon {
  position: relative;
  display: inline-block;
}

.school-icon::after {
  content: '🎓';
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 16px;
  transform: rotate(15deg);
  animation: bounce 2s ease-in-out infinite;
}

.book-icon::after {
  content: '📚';
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 16px;
  transform: rotate(-15deg);
  animation: float 3s ease-in-out infinite;
}

.star-icon::after {
  content: '⭐';
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 16px;
  animation: bounce 2s ease-in-out infinite;
}
