.academic-calendar-container {
  padding: 24px;
  background: #f0f2f5;
  min-height: 100vh;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.calendar-header h1 {
  margin: 0;
  color: #1f1f1f;
}

.calendar-content {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.calendar-card {
  margin-bottom: 24px;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events li {
  margin-bottom: 4px;
}

.events-card {
  margin-bottom: 24px;
}

.next-holiday-card {
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  color: white;
}

.next-holiday-info {
  text-align: center;
  padding: 16px;
}

.next-holiday-info h3 {
  margin: 0 0 8px 0;
  font-size: 20px;
  font-weight: 500;
}

.next-holiday-info p {
  margin: 4px 0;
  font-size: 16px;
  opacity: 0.9;
}

/* Ant Design Calendar Customization */
.ant-picker-calendar {
  background: transparent;
}

.ant-picker-calendar-date {
  height: 80px;
}

.ant-picker-calendar-date-content {
  height: 40px;
}

.ant-picker-calendar-date-value {
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .academic-calendar-container {
    padding: 16px;
  }

  .calendar-content {
    padding: 16px;
  }

  .calendar-header {
    flex-direction: column;
    gap: 16px;
    text-align: center;
  }

  .calendar-header h1 {
    font-size: 24px;
  }
} 